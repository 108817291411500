<template>
   <div class="layout-px-spacing" v-if="
        this.$can('admin') ||
        this.$can('office') ||
        this.$can('technik-manager') ||
        this.$can('obchodnik-manager') ||
        this.$can('rola-pobocka') ||
        this.$can('skladnik') ||
        this.$can('kurier') ||
        this.$can('infolinka') ||
        this.$can('technik')
   ">
      <portal to="breadcrumb">
         <ul class="navbar-nav flex-row">
            <li>
               <div class="page-header">
                  <nav class="breadcrumb-one" aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:;">Users</a></li>
                        <li class="breadcrumb-item active" aria-current="page"><span>Profile</span></li>
                     </ol>
                  </nav>
               </div>
            </li>
         </ul>
      </portal>

      <div class="row layout-spacing">
         <div class="col-lg-12 layout-top-spacing">
            <div class="panel">
               <div class="panel-heading">
                  <div class="row">
                     <div class="col-12">
                        <h3>Vytvoriť novú samostatnú {{ type === "sam-prijemka" ? "príjemku" : "výdajku" }}</h3>
                     </div>
                  </div>
               </div>
               <div class="panel-body">
                  <b-form @submit.prevent="submitForm" ref="form">
                     <div class="row align-items-center mb-md-4">
                        <div class="col-12 mt-0">
                           <b-form-row class="over-1">
                              <b-form-group class="col-md-6" label="Odosielateľ">
                                 <b-form-input :state="validateState('from_user_id')" readonly
                                               v-if="form.from_user_id" v-model="form.from_user_id.pouzivatel.name" type="text"
                                               placeholder=""></b-form-input>
                              </b-form-group>
                              <b-form-group class="col-md-6" label="Príjemca">
                                 <b-form-input :state="validateState('other_user_name')"
                                               v-model="form.other_user_name" type="text"
                                               placeholder=""></b-form-input>
                              </b-form-group>

                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-md-12" label="Produkty">
                                 <div class="form-repeater w-100 custom-table">
                                    <table class="table table-responsive-detail">
                                       <thead>
                                          <tr>
                                             <th style="width: 30%;min-width: 200px">Názov</th>
                                             <th style="width: 30%;min-width: 200px">Počet</th>
                                             <th style="width: 30%;min-width: 200px">Poznámka</th>
                                             <th class="text-center">Odstrániť</th>
                                          </tr>
                                       </thead>
                                       <tbody class="form-repeater">
                                          <tr v-for="(row, index) in form.rows" :key="index">
                                             <td>
                                                <multiselect :max-height="200" :showNoResults="false" :key="row.id" v-model="form.rows[index]" deselectLabel="Vymazať" selectLabel="Vybrať" selectedLabel="Vybrané" tag-placeholder="Pridajte to ako novú značku" placeholder="Vyhľadať" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="items" :multiple="false" :closeOnSelect="true"></multiselect>
                                             </td>
                                             <td>
                                                <b-input-group class="">
                                                   <template #append>
                                                      <b-input-group-text><strong v-if="row.unit" class="">{{ row.unit.name }}</strong></b-input-group-text>
                                                   </template>
                                                   <b-form-input v-model="row.quantity" type="number" min="0" placeholder=""></b-form-input>
                                                </b-input-group>
                                                <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                                             </td>
                                             <td>
                                                <b-form-input v-model="row.note_custom" type="text" placeholder=""></b-form-input>
                                             </td>
                                             <td class="text-center align-middle">
                                                <b-button variant="outline-danger" @click="removeRow(index)">
                                                   <svg
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="24"
                                                     height="24"
                                                     viewBox="0 0 24 24"
                                                     fill="none"
                                                     stroke="currentColor"
                                                     stroke-width="2"
                                                     stroke-linecap="round"
                                                     stroke-linejoin="round"
                                                     class="feather feather-trash-2"
                                                   >
                                                      <polyline points="3 6 5 6 21 6"></polyline>
                                                      <path
                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                      <line x1="10" y1="11" x2="10" y2="17"></line>
                                                      <line x1="14" y1="11" x2="14" y2="17"></line>
                                                   </svg>
                                                </b-button>
                                             </td>
                                          </tr>

                                          <tr class="text-md-center">
                                             <td colspan="6">
                                                <b-button variant="outline-dark" @click="addRow">Pridať riadok</b-button>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </b-form-group>
                           </b-form-row>

                           <b-form-row>
                              <b-form-group class="col-md-12" label="Globálna poznámka">
                                 <b-form-textarea id="textarea" v-model="$v.form.note.$model" laceholder="Poznámka..."
                                                  rows="4"></b-form-textarea>
                              </b-form-group>
                           </b-form-row>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12 text-right">
                           <b-button @click="$router.go(-1)" variant="cancel" class="mt-3">Zrušiť</b-button>
                           <b-button type="submit" variant="default" class="mt-3 ml-2">Vytvoriť</b-button>
                        </div>
                     </div>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">Nemáte oprávnění zobrazit tuto stránku.</h4>
         </div>
      </div>
   </div>
</template>

<script>
   import { validationMixin } from "vuelidate";
   import { required } from "vuelidate/lib/validators";
   import Multiselect from "vue-multiselect";
   import router from "@/router";

   export default {
      name: "new",
      mixins: [validationMixin],
      components: { Multiselect },
      validations: {
         form: {
            name: {},
            from_user_id: {
               required
            },
            other_user_name: {
               required
            },
            note: {},
            rows: {
               $each: {
                  id: {
                     required
                  },
                  quantity: {
                     required
                  }
               }
            }
         }
      },
      data() {
         return {
            type: "",
            form: {
               name: "",
               from_user_id: null,
               other_user_name: null,
               note: "",
               rows: [{ id: null, quantity: "", note: "", note_custom: "" }]
            }
         };
      },
      created() {
         this.type = router.currentRoute.params.type;
         this.$axios.get("user")
           .then(response => {
              this.form.from_user_id = response.data;
           });
         // this.$store.dispatch("user/getUsers");
         this.$store.dispatch("item/getItems", {all: 1});
      },
      computed: {
         // users() {
         //   return this.$store.state.user.users;
         // },
         items() {
            return this.$store.state.item.items;
         }
      },
      methods: {
         addRow() {
            this.form.rows.push({
               id: null,
               quantity: "",
               note: ""
            });
         },
         removeRow(index) {
            this.form.rows.splice(index, 1);
         },
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
         },
         submitForm() {
            // this.form.rows.forEach(function (value) {
            //   if (value.object == null || value.quantity == null) {
            //     this.errors.push('Item is not correct');
            //   }
            // })


            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
               console.log(this.form);

               this.$toast.error("Chýbajúce údaje vo formulári");
               return;
            }

            let data = {
               name: this.form.name,
               from_user_id: this.form.from_user_id.pouzivatel.id,
               other_user_name: this.form.other_user_name,
               note: this.form.note,
               type: "SELF_OUT"
            };

            let formData = new FormData();

            for (let key in data) {
               formData.append(key, data[key]);
            }


            this.form.rows.forEach(function(value, index) {
               // if (value.object !== null && value.object !== undefined) {
               formData.append("items[" + index + "][id]", value.id);// you have to add array symbol after the key name
               formData.append("items[" + index + "][quantity]", value.quantity);// you have to add array symbol after the key name
               formData.append("items[" + index + "][note]", value.note_custom);// you have to add array symbol after the key name
               // }
            });
            // Display the key/value pairs


            this.$axios.post("movements", formData).then((response) => {
               console.log(response);
               this.$toast.success(response.data.message);
               this.$router.go(- 1);

            }).catch(error => {
               this.$toast.error(error.response.data.message);
            });
         }
      }
   };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
   @import '@/assets/sass/global/table.scss';
</style>
